import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from '@/interfaces/user.interface';
import { findLoggedUser, signInUser } from '../actions/session.action';
import { Product } from '@/interfaces/product.interface';
import { findProducts } from '../actions/product.action';

export interface ProductState {
  products: Product[];
  loading: boolean;
  error: string | undefined;
}

const initialState: ProductState = {
  products: [],
  loading: false,
  error: undefined,
};

export const stateLoadingHandler = (state: any) => {
  state.loading = true;
}

export const stateErrorHandler = (state: any, err: any) => {
  state.loading = false;
  state.error = err.error.message;
}

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    clearProduct: (state) => {
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      findProducts.fulfilled,
      (state, { payload }: PayloadAction<Product[]>) => {
        state.products = payload;
        state.loading = false;
      },
    );
    builder.addCase(findProducts.pending, stateLoadingHandler);
    builder.addCase(findProducts.rejected, stateErrorHandler);
  },
});

export const { clearProduct } = productSlice.actions;
export default productSlice.reducer;
