import logo from '@/public/logo/logofull.png';
import Image from 'next/image';
import { Menubar } from 'primereact/menubar';
import styles from './layout.module.scss';
import useLoggedUser from '@/hooks/use-logged-user';
import { useAppSelector } from '@/redux/store';
import Link from 'next/link';
import PTText from '../text/pt-text';
import PTButton from '../button/pt-button';
import Loading from '../loading/loading';
import getMenuItems from './menu-items';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';

interface Props {
  children: JSX.Element;
}

function Layout({ children }: Props) {
  const [isLoading, handleLogout] = useLoggedUser();
  const { user } = useAppSelector((state) => state.session);
  const router = useRouter();
  const { toast: toastStore, loading } = useAppSelector(
    (state) => state.common,
  );
  const toast = useRef<Toast>(null);

  const onRoute = (route: string) => router.push(route);

  useEffect(() => {
    if (toastStore) {
      toast.current?.show(toastStore);
    }
  }, [toastStore]);

  return (
    <div className="layout-container">
      <ConfirmDialog />
      <Toast ref={toast} />
      <Loading isLoading={isLoading || loading} />
      <div className={`${styles.navigation}`}>
        <div className={styles.navImage}>
          <Link href="/">
            <Image src={logo} height={70} alt="logo" />
          </Link>
        </div>
        <div className={styles.navContainer}>
          <Menubar className="nav-items" model={getMenuItems(user, onRoute)} />
          <PTText
            size="xs"
            weight="500"
            style={{ marginRight: '1.2rem', textTransform: 'capitalize' }}
          >
            {user?.name ?? ''}
          </PTText>
          {!user ? (
            <Link href="/login">
              <PTButton isMain={true} size="sm">
                Ingresar o Registrarse
              </PTButton>
            </Link>
          ) : (
            <PTButton isMain={true} onClick={handleLogout} size="sm">
              Cerrar Sesión
            </PTButton>
          )}
        </div>
      </div>
      {children}
    </div>
  );
}

export default Layout;
