import { User } from '@/interfaces/user.interface';
import { useAppSelector } from '@/redux/store';
import { MenuItem } from 'primereact/menuitem';

function getMenuItems(
  user: User | null,
  onRoute: (route: string) => void,
): MenuItem[] {
  return [
    {
      label: 'Servicios',
      items: [
        { label: 'Determinar tus obligaciones de Precios de Transferencia' },
        { label: 'Elaborar la Declaración de Precios de Transferencia' },
        {
          label:
            'Asistencia en la presentación de tus obligaciones de Precios de Transferencia',
        },
        { label: 'Otros servicios' },
      ],
    },
    ...(user?.role === 'customer'
      ? [
          {
            label: 'Mis Declaraciones',
            command: () => onRoute(`/portal/${user.id}`),
          },
        ]
      : []),
    ...(user?.role === 'admin'
      ? [
          {
            label: 'Admin',
            items: [
              {
                label: 'Usuarios',
                command: () => onRoute('/admin/usuarios'),
              },
              {
                label: 'Admin. Blog',
                command: () => onRoute('/admin/blog'),
              },
              {
                label: 'Fechas Máximas',
                command: () => onRoute('/admin/fechas-maximas'),
              },
              {
                label: 'Servicios',
                command: () => onRoute('/admin/servicios'),
              },
              {
                label: 'Preguntas Verificación',
                command: () => onRoute('/admin/preguntas-verificacion'),
              },
            ],
          },
        ]
      : []),
    {
      label: 'Blog',
      command: () => onRoute('/blog'),
    },
    {
      label: 'Ayuda',
      items: [
        { label: 'Preguntas frecuentes' },
        {
          label: 'Normatividad de Precios de Transferencia',
          command: () =>
            onRoute(
              'https://drive.google.com/uc?id=1izcZxgLbTOHsjUeXqKjgQPjlseKP7OjM&export=download',
            ),
        },
        {
          label: 'Chat, acá siempre nos podrás contactar',
        },
      ],
    },
  ];
}

export default getMenuItems;
