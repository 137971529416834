import { api, handleApiError } from "@/helpers/api.helper";
import { Product } from "@/interfaces/product.interface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

export const findProducts = createAsyncThunk<Product[]>(
  'product/findProducts',
  async (_, { rejectWithValue }) => {
    try {
      const { data: products } = await api.get<Product[]>('products');
      return products;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);