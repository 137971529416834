import { combineReducers } from "@reduxjs/toolkit";
import sessionReducer from "./slices/session.slice";
import productReducer from "./slices/product.slice";
import declarationReducer from './slices/declaration.slice';
import commonReducer from './slices/common.slice'

const appReducer = combineReducers({
  session: sessionReducer,
  product: productReducer,
  declaration: declarationReducer,
  common: commonReducer,
});

// export type RootState = ReturnType<typeof appReducer>;
export default appReducer;