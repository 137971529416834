import { HTMLAttributes, ReactNode, useEffect, useState } from 'react';
import styles from './text.module.scss';
import TooltipOptions from 'primereact/tooltip/tooltipoptions';
import { Tooltip } from 'primereact/tooltip';

export interface PTTextProps
  extends Omit<HTMLAttributes<HTMLSpanElement>, 'children'> {
  children: string | ReactNode;
  weight?: '300' | '400' | '500' | '600' | '700';
  size?: 'xxxl' | 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs' | 'xxxs';
  asTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  withQuestionMark?: boolean;
  tooltip?: string;
  tooltipOptions?: TooltipOptions;
  tooltipTarget?: string;
}

export default function PTText({
  children,
  weight = '400',
  size = 'md',
  asTag,
  withQuestionMark = false,
  tooltip,
  tooltipOptions,
  tooltipTarget,
  ...props
}: PTTextProps) {
  const [customSizeClass, setCustomSizeClass] = useState('');
  const [customWeightClass, setCustomWeightClass] = useState('');

  const setSizeStyles = () => {
    let styleClass: string = '';
    switch (size) {
      case 'xxxl':
        styleClass = styles.textXxxl;
        break;
      case 'xxl':
        styleClass = styles.textXxl;
        break;
      case 'xl':
        styleClass = styles.textXl;
        break;
      case 'md':
        styleClass = styles.textMd;
        break;
      case 'lg':
        styleClass = styles.textLg;
        break;
      case 'sm':
        styleClass = styles.textSm;
        break;
      case 'xs':
        styleClass = styles.textXs;
        break;
      case 'xxs':
        styleClass = styles.textXxs;
        break;
      case 'xxxs':
        styleClass = styles.textXxxs;
      default:
        break;
    }
    setCustomSizeClass(styleClass);
  };

  const setWeightStyles = () => {
    let styleClass: string = '';
    switch (weight) {
      case '300':
        styleClass = styles.textW300;
        break;
      case '400':
        styleClass = styles.textW400;
        break;
      case '500':
        styleClass = styles.textW500;
        break;
      case '600':
        styleClass = styles.textW600;
        break;
      case '700':
        styleClass = styles.textW700;
      default:
        break;
    }
    setCustomWeightClass(styleClass);
  };

  useEffect(setWeightStyles, [weight]);
  useEffect(setSizeStyles, [size]);

  const classes = `${styles.mainText} ${customWeightClass} ${customSizeClass} ${props.className}`;
  const initialQuestionMark = withQuestionMark ? (
    <span className={styles.initialQuestionMark}>¿</span>
  ) : null;
  const endingQuestionMark = withQuestionMark ? (
    <span className={styles.endingQuestionMark}>?</span>
  ) : null;

  const renderAsTag = (content: ReactNode) => {
    let textComponent;
    switch (asTag) {
      case 'h1':
        textComponent = <h1
          data-pr-tooltip={tooltip}
          data-pr-position={tooltipOptions?.position}
          className={`${classes} ${tooltipTarget}`}
        >{content}</h1>;
        break;
      case 'h2':
        textComponent = <h2
          data-pr-tooltip={tooltip}
          data-pr-position={tooltipOptions?.position}
          className={`${classes} ${tooltipTarget}`}
        >{content}</h2>;
        break;
      case 'h3':
        textComponent = <h3 data-pr-tooltip={tooltip}
          data-pr-position={tooltipOptions?.position}
          className={`${classes} ${tooltipTarget}`}>{content}</h3>;
        break;
      case 'h4':
        textComponent = <h4 data-pr-tooltip={tooltip}
          data-pr-position={tooltipOptions?.position}
          className={`${classes} ${tooltipTarget}`}>{content}</h4>;
        break;
      case 'h5':
        textComponent = <h5 data-pr-tooltip={tooltip}
          data-pr-position={tooltipOptions?.position}
          className={`${classes} ${tooltipTarget}`}>{content}</h5>;
        break;
      case 'h6':
        textComponent = <h6 data-pr-tooltip={tooltip}
          data-pr-position={tooltipOptions?.position}
          className={`${classes} ${tooltipTarget}`}>{content}</h6>;
        break;
      default:
        textComponent = (
          <span {...props}
            className={`${classes} ${tooltipTarget}`}
            data-pr-tooltip={tooltip}
            data-pr-position={tooltipOptions?.position}>
            {content}
          </span>
        );
        break;
    }
    return (
      <>
        <Tooltip target={`.${tooltipTarget}`} />
        {textComponent}
      </>
    )
  };

  return renderAsTag(
    <>
      {initialQuestionMark}
      {children}
      {endingQuestionMark}
    </>,
  );
}
