import { OperationType } from '@/enums/declaration.enum';
import { api, handleApiError } from '@/helpers/api.helper';
import {
  DeclarationCbcInform,
  DeclarationCompany,
  DeclarationOperation,
  DeclarationShareholder,
  DeclarationSubmission,
} from '@/interfaces/declaration.interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

//DECLARATION SUBMISSION
export const getDeclarationSubmission = createAsyncThunk<
  DeclarationSubmission,
  number | undefined
>(
  'declaration/getDeclarationSubmission',
  async (declarationSubmissionId, { rejectWithValue }) => {
    try {
      const { data: submission } = await api.get<DeclarationSubmission>(
        `declaration-submissions/${declarationSubmissionId}`,
      );
      return submission;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

export const getUserDeclarationSubmissions = createAsyncThunk<
  DeclarationSubmission[],
  void
>(
  'declaration/getUserDeclarationSubmissions',
  async (_, { rejectWithValue }) => {
    try {
      const { data: submission } = await api.get<DeclarationSubmission[]>(
        `declaration-submissions`,
      );
      return submission;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

export const createDeclarationSubmission = createAsyncThunk<
  DeclarationSubmission,
  DeclarationSubmission
>(
  'declaration/createDeclarationSubmission',
  async (declarationValues, { rejectWithValue }) => {
    try {
      const { data: declaration } = await api.post<DeclarationSubmission>(
        'declaration-submissions',
        declarationValues,
      );
      return declaration;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

export const updateDeclarationSubmission = createAsyncThunk<
  DeclarationSubmission,
  DeclarationSubmission
>(
  'declaration/updateDeclarationSubmission',
  async ({ id, ...declarationValues }, { rejectWithValue }) => {
    try {
      const { data: declaration } = await api.patch<DeclarationSubmission>(
        `declaration-submissions/${id}`,
        declarationValues,
      );
      return declaration;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

//DECLARATION SHAREHOLDER
//retorna-recibe
export const createDeclarationShareholder = createAsyncThunk<
  void,
  { declarationSubmissionId: number; shareholders: DeclarationShareholder[] }
>(
  'declaration/createDeclarationShareholder',
  async (declarationValues, { rejectWithValue }) => {
    try {
      await api.post('declaration-shareholders', declarationValues);
      return;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

export const deleteDeclarationShareholder = createAsyncThunk<
  void,
  number | undefined
>(
  'declaration/deleteDeclarationShareholder',
  async (declarationShareholderId, { rejectWithValue }) => {
    try {
      await api.delete(`declaration-shareholders/${declarationShareholderId}`);
      return;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

export const getDeclarationShareholders = createAsyncThunk<
  DeclarationShareholder[],
  number | undefined
>(
  'declaration/getDeclarationShareholders',
  async (declarationSubmissionId, { rejectWithValue }) => {
    try {
      const { data: shareholders } = await api.get<DeclarationShareholder[]>(
        `declaration-shareholders/${declarationSubmissionId}`,
      );
      return shareholders;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

// DECLARATION COUNTRY BY COUNTRY
export const createDeclarationCbcInform = createAsyncThunk<
  DeclarationCbcInform,
  { cbcInform: DeclarationCbcInform; declarationSubmissionId: number }
>(
  'declaration/createDeclarationCbcInform',
  async (declarationCbcValues, { rejectWithValue }) => {
    try {
      const { data: declarationCbc } = await api.post<DeclarationCbcInform>(
        'declaration-cbc-inform',
        declarationCbcValues,
      );
      return declarationCbc;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

// DECLARATION COMPANIES
export const createDeclarationCompany = createAsyncThunk<
  void,
  { declarationSubmissionId: number; company: DeclarationCompany }
>(
  'declaration/createDeclarationCompany',
  async (declarationValues, { rejectWithValue }) => {
    try {
      await api.post('declaration-companies', declarationValues);
      return;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

export const updateDeclarationCompany = createAsyncThunk<
  void,
  { declarationCompanyId: number; company: DeclarationCompany }
>(
  'declaration/updateDeclarationCompany',
  async ({ declarationCompanyId, company }, { rejectWithValue }) => {
    try {
      await api.patch(`declaration-companies/${declarationCompanyId}`, company);
      return;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

export const deleteDeclarationCompany = createAsyncThunk<
  void,
  number | undefined
>(
  'declaration/deleteDeclarationCompany',
  async (companyId, { rejectWithValue }) => {
    try {
      await api.delete(`declaration-companies/${companyId}`);
      return;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

export const getDeclarationCompanies = createAsyncThunk<
  DeclarationCompany[],
  number | undefined
>(
  'declaration/getDeclarationCompanies',
  async (declarationSubmissionId, { rejectWithValue }) => {
    try {
      const { data: companies } = await api.get<DeclarationCompany[]>(
        `declaration-companies/${declarationSubmissionId}`,
      );
      return companies;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

export const createDeclarationOperation = createAsyncThunk<
  void,
  {
    declarationSubmissionId: number;
    declarationOperation: DeclarationOperation;
  }
>(
  'declaration/createDeclarationOperation',
  async (declarationValues, { rejectWithValue }) => {
    try {
      await api.post('declaration-operations', declarationValues);
      return;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

export const updateDeclarationOperation = createAsyncThunk<
  void,
  { declarationOperationId: number; declarationOperation: DeclarationOperation }
>(
  'declaration/updateDeclarationCompany',
  async (
    { declarationOperationId, declarationOperation },
    { rejectWithValue },
  ) => {
    try {
      await api.patch(
        `declaration-operations/${declarationOperationId}`,
        declarationOperation,
      );
      return;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

export const deleteDeclarationOperation = createAsyncThunk<
  void,
  number | undefined
>(
  'declaration/deleteDeclarationOperation',
  async (declarationOperationId, { rejectWithValue }) => {
    try {
      await api.delete(`declaration-operations/${declarationOperationId}`);
      return;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);

export const getDeclarationOperations = createAsyncThunk<
  DeclarationOperation[],
  { declarationOperationId: number; type: OperationType }
>(
  'declaration/getDeclarationOperations',
  async ({ declarationOperationId, type }, { rejectWithValue }) => {
    try {
      const { data: declarationOperations } = await api.get<
        DeclarationOperation[]
      >(`declaration-operations/${declarationOperationId}?type=${type}`);
      return declarationOperations;
    } catch (err) {
      return rejectWithValue(handleApiError(err as AxiosError));
    }
  },
);
