import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { findLoggedUser } from '../redux/actions/session.action';
import { clearSession } from '../redux/slices/session.slice';
import { useAppDispatch } from '../redux/store';
import { auth } from '../utils/firebase';

const useLoggedUser = (): [boolean, () => void] => {
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();
  const dispatch = useAppDispatch();

  const checkIsUserLogged = () => {
    dispatch(findLoggedUser());
    setIsLoading(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('tkn');
    dispatch(clearSession());
    auth.signOut();

    router.push(`/login`);
  };

  useEffect(checkIsUserLogged, [router.pathname]);

  return [isLoading, handleLogout];
};

export default useLoggedUser;
