import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  DeclarationBindingTypes,
  DeclarationCompany,
  DeclarationShareholder,
  DeclarationSubmission,
  DeclarationCbcInform,
  DeclarationOperationType,
  DeclarationOperation,
} from '@/interfaces/declaration.interface';
import {
  createDeclarationCompany,
  createDeclarationShareholder,
  createDeclarationSubmission,
  getDeclarationShareholders,
  getDeclarationSubmission,
  getUserDeclarationSubmissions,
  createDeclarationCbcInform,
  updateDeclarationSubmission,
  createDeclarationOperation,
  getDeclarationOperations,
  updateDeclarationCompany,
} from '../actions/declaration.action';

export interface DeclarationState {
  userSubmissions: DeclarationSubmission[];
  declarationSubmission: DeclarationSubmission | null;
  loading: boolean;
  error: string | undefined;
  shareholders: DeclarationShareholder[];
  cbcInform: DeclarationCbcInform | null;
  companies: DeclarationCompany[];
  bindingTypes: DeclarationBindingTypes[];
  operationTypes: DeclarationOperationType[];
  declarationOperations: DeclarationOperation[];
}

const initialState: DeclarationState = {
  userSubmissions: [],
  declarationSubmission: null,
  loading: false,
  error: undefined,
  shareholders: [],
  cbcInform: null,
  companies: [],
  bindingTypes: [],
  operationTypes: [],
  declarationOperations: [],
};

export const stateLoadingHandler = (state: any) => {
  state.loading = true;
};

export const stateErrorHandler = (state: any, err: any) => {
  state.loading = false;
  state.error = err.error.message;
};

export const declarationSlice = createSlice({
  name: 'declaration',
  initialState,
  reducers: {
    clearProduct: (state) => {
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
  },
  extraReducers(builder) {
    // SUBMISSION
    builder.addCase(
      getUserDeclarationSubmissions.fulfilled,
      (state, { payload }: PayloadAction<DeclarationSubmission[]>) => {
        state.userSubmissions = payload;
        state.loading = false;
      },
    );
    builder.addCase(getUserDeclarationSubmissions.pending, stateLoadingHandler);
    builder.addCase(getUserDeclarationSubmissions.rejected, stateErrorHandler);

    builder.addCase(
      getDeclarationSubmission.fulfilled,
      (state, { payload }: PayloadAction<DeclarationSubmission>) => {
        state.declarationSubmission = payload;
        state.loading = false;
      },
    );
    builder.addCase(getDeclarationSubmission.pending, stateLoadingHandler);
    builder.addCase(getDeclarationSubmission.rejected, stateErrorHandler);

    builder.addCase(
      createDeclarationSubmission.fulfilled,
      (state, { payload }: PayloadAction<DeclarationSubmission>) => {
        state.declarationSubmission = payload;
        state.loading = false;
      },
    );
    builder.addCase(createDeclarationSubmission.pending, stateLoadingHandler);
    builder.addCase(createDeclarationSubmission.rejected, stateErrorHandler);

    builder.addCase(
      updateDeclarationSubmission.fulfilled,
      (state, { payload }: PayloadAction<DeclarationSubmission>) => {
        state.declarationSubmission = payload;
        state.loading = false;
      },
    );
    builder.addCase(updateDeclarationSubmission.pending, stateLoadingHandler);
    builder.addCase(updateDeclarationSubmission.rejected, stateErrorHandler);

    // SHAREHOLDERS
    builder.addCase(createDeclarationShareholder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createDeclarationShareholder.pending, stateLoadingHandler);
    builder.addCase(createDeclarationShareholder.rejected, stateErrorHandler);

    builder.addCase(
      getDeclarationShareholders.fulfilled,
      (state, { payload }: PayloadAction<DeclarationShareholder[]>) => {
        state.loading = false;
        state.shareholders = payload;
      },
    );
    builder.addCase(getDeclarationShareholders.pending, stateLoadingHandler);
    builder.addCase(getDeclarationShareholders.rejected, stateErrorHandler);

    // DECLARATION CBC
    builder.addCase(createDeclarationCbcInform.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createDeclarationCbcInform.pending, stateLoadingHandler);
    builder.addCase(createDeclarationCbcInform.rejected, stateErrorHandler);

    // COMPANIES

    builder.addCase(createDeclarationCompany.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createDeclarationCompany.pending, stateLoadingHandler);
    builder.addCase(createDeclarationCompany.rejected, stateErrorHandler);
    builder.addCase(updateDeclarationCompany.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateDeclarationCompany.pending, stateLoadingHandler);
    builder.addCase(updateDeclarationCompany.rejected, stateErrorHandler);

    // DECLARATION OPERATION
    builder.addCase(createDeclarationOperation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createDeclarationOperation.pending, stateLoadingHandler);
    builder.addCase(createDeclarationOperation.rejected, stateErrorHandler);

    builder.addCase(
      getDeclarationOperations.fulfilled,
      (state, { payload }: PayloadAction<DeclarationOperation[]>) => {
        state.loading = false;
        state.declarationOperations = payload;
      },
    );
    builder.addCase(getDeclarationOperations.pending, stateLoadingHandler);
    builder.addCase(getDeclarationOperations.rejected, stateErrorHandler);
  },
});

export const { clearProduct } = declarationSlice.actions;
export default declarationSlice.reducer;
